.fanzhabg {
    width: 100%;
    height: 100vh;
    background-image: url('../img/fanzha/background.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.top {
    width: 100%;
    height: 42vh;
    background-image: url('../img/background3.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.fanzhaTop {
    width: 100%;
    height: 42vh;
    background-image: url('../img/fanzha/backgroundTop-1.png');
    margin: 20px;
    background-size: 85% 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.content {
    border-radius: 10px;
    margin: 0 auto;
    width: 90%;
    background-color: #3c009c;
    text-align: center;
    padding: 0 0 20px 0;
}

.fanzhaContent {
    width: 100%;
    height: 30vh;
    background-image: url('../img/fanzha/textbox-1.png');
    background-size: 80% 80%;
    background-repeat: no-repeat;
    background-position: center;
}

.activityContent {
    padding: 0 20px;
    text-align: left;
    color: #ffffff;
    margin: 10px 0 0 0;
    height: 40vh;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.fanzhaSynopsis {
    padding: 70px 40px 0 40px;
}

.btn {
    margin: 10% 0 2% 0;
    border-radius: 50px;
    width: 200px;
    height: 50px;
    background-color: #ff7f27;
    color: #ffffff;
    font-size: 18px;
    border: 0px;
    box-shadow: 5px 5px 5px rgba(41, 0, 0, 0.87);
    -webkit-box-shadow: 5px 5px 5px rgba(41, 0, 0, 0.87);
    -moz-box-shadow: 5px 5px 5px rgba(41, 0, 0, 0.87);
}

.btn:focus {
    border: #ffffff 3px solid;
}

.fanzhabtn {
    margin: 10% 0 2% 0;
    width: 100%;
    height: 10vh;
    background-image: url('../img/fanzha/start-button.png');
    background-size: 60% 85%;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.fanzhabtn:focus {
    border: #1e2b57 3px solid;
}

.ant-select-dropdown .ant-select-item-option-content {
    font-size: 18px;
}