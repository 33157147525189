.scratch {
    width: 80%;
}

.s1 {
    font-size: 24px;
    line-height: 80px;
    height: 80px;
    width: 100%;
    color: #FFD700;
    display: inline-block;
}

.fanzhabg {
    width: 100%;
    height: 100vh;
    background-image: url('../img/fanzha/background.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.rewardDiv {
    width: 100%;
    height: 50vh;
    background-image: url('../img/reward.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    padding: ''
}

.fanzhaRewardDiv {
    width: 100%;
    height: 50vh;
    background-image: url('../img/fanzha/fanzhaReward.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.imgbtn {
    display: block;
    margin: 5% 0;
    border-radius: 50px;
    width: 200px;
    background-color: #ff7f27;
    color: #ffffff;
    height: 50px;
    border: 0px;
    font-size: 20px;
    box-shadow: 5px 5px 5px rgba(41, 0, 0, 0.87);
    -webkit-box-shadow: 5px 5px 5px rgba(41, 0, 0, 0.87);
    -moz-box-shadow: 5px 5px 5px rgba(41, 0, 0, 0.87);
}

.imgbtn:focus {
    border: #ffffff 3px solid;
}

.fanzhaImgbtn {
    display: block;
    margin: 5% 0;
    border-radius: 50px;
    width: 200px;
    background-color: #1e2b57;
    color: #ffffff;
    height: 50px;
    border: 0px;
    font-size: 20px;
    box-shadow: 5px 5px 5px rgba(0, 9, 61, 0.87);
    -webkit-box-shadow: 5px 5px 5px rgba(1, 0, 49, 0.87);
    -moz-box-shadow: 5px 5px 5px rgba(6, 0, 59, 0.87);
}

.fanzhaImgbtn:focus {
    border: #ffffff 3px solid;
}