.fanzhabg {
    width: 100%;
    height: 100vh;
    background-image: url('../img/fanzha/background.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.header {
    width: 100%;
    height: 25vh;
    background-image: url('../img/top_bg.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.fanzhaHeader {
    width: 100%;
    height: 30vh;
    flex: 0.7;
    background-image: url('../img/fanzha/backgroundTop-2.png');
    background-size: 90% 90%;
    background-repeat: no-repeat;
    background-position: center;
}

.mainContent {
    border-radius: 10px;
    margin: 0 auto;
    width: 90%;
    background-color: #3c009c;
    text-align: center;
}

.fanzhaMainContent {
    width: 100%;
    text-align: center;
    padding-top: 10px;
    background-image: url('../img/fanzha/textbox-2.png');
    background-size: 90% 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.btn {
    margin: 5% 0;
    border-radius: 50px;
    width: 200px;
    height: 50px;
    background-color: #ff7f27;
    color: #ffffff;
    font-size: 18px;
    border: 0px;
    box-shadow: 5px 5px 5px rgba(41, 0, 0, 0.87);
    -webkit-box-shadow: 5px 5px 5px rgba(41, 0, 0, 0.87);
    -moz-box-shadow: 5px 5px 5px rgba(41, 0, 0, 0.87);
}

.btn:focus {
    border: #ffffff 3px solid;
}

.fanzhaBtn {
    margin: 10% 0 2% 0;
    width: 100%;
    flex: 0.2;
    height: 10vh;
    background-image: url('../img/fanzha/next-button.png');
    background-size: 60% 85%;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.fanzhaBtn:focus {
    border: #1e2b57 3px solid;
}